/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  margin: 0 !important;
  padding: 0 !important;
}

.centered-input input {
  text-align: center;
}

.label-sup {
  margin-bottom: 20px;
  margin-left: -30px;
  margin-right: 10px;
}
